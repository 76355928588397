<template>
    <b-card-code title="Advance Search Table">
      <div class="custom-search">
  
        <!-- advance search input -->
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Name:</label>
              <b-form-input
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="advanceSearch"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email:</label>
              <b-form-input
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="advanceSearch"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Post:</label>
              <b-form-input
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="advanceSearch"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City:</label>
              <b-form-input
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="advanceSearch"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Date:</label>
              <b-form-input
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="advanceSearch"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Salary:</label>
              <b-form-input
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="advanceSearch"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
  
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        theme="my-theme"
        @on-row-click="onRowClick"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field === 'graguated'"
            class="text-nowrap"
          >
            <span>{{ props.row.graguated.begin }} - {{ props.row.graguated.end }}</span>
          </span>
          <!-- <span v-else>
            لم يعين 
          </span> -->
        </template>
  
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <!-- <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div> -->
          </div>
        </template>
      </vue-good-table>
  
    </b-card-code>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { VueGoodTable } from 'vue-good-table'
  import store from '@/store/index'
  
  export default {
    components: {
      BCardCode,
      VueGoodTable,
      BAvatar,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BRow,
      BCol,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
    },
    data() {
      return {
        pageLength: 5,
        dir: false,
        currentPage:0,
        columns: [
          {
            label: 'اسم الطالب',
            field: 'name',
          },
          {
            label: 'القسم',
            field: 'department.ar_name',
          },
          {
            label: 'مقبول في',
            field: 'type.ar_title',
          },
          {
            label: 'الفرع العلمي',
            field: 'branch.title',
          },
          {
            label: 'الجنس',
            field: 'gender.ar_title',
          },
          {
            label: 'البلد',
            field: 'country.country_arName',
          },
          {
            label: 'تخرج الاعدادية',
            field: 'graguated',
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    computed: {
      direction() {
        if (store.state.appConfig.isRTL) {
          this.dir = true
          return this.dir
        }
        this.dir = false
        return this.dir
      },
    },
    created() {
    this.gS()
  },
  methods: {
      async gS()  {
    await this.axios
      .get(
        `stdinfo?take=${this.pageLength}&skip=${
          this.currentPage - 1
        }`
      )
      .then((res) => {
        this.rows = [];
        this.totalRows = res.data.totalCount;
        this.rows = res.data.items;
      })
      .catch((e) => {
        if (e.message.includes("401")) {
          this.makeToast(
            "danger",
            "update failed",
            "you don't a permision to do this action ;)"
          );
        } else {
          this.errorToast();
        }
      });
  },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
  },
  }
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
  